import OperatorChangeView from '@/views/static/OperatorChangeView.vue'
import AddressSearchView from '@/apps/address-search/views/AddressSearchView'
import LoginLandingView from '@/views/login-landing/LoginLandingView.vue'
import MyReportsView from '@/views/my-reports/MyReportsView.vue'
import ReportsAdministrationView from '@/views/reports-administration/ReportsAdministrationView'
import ReportsUploadView from '@/views/reports-upload/ReportsUploadView'
import AreaSearchView from '@/views/static/search-tools/area-search/AreaSearchView'
import AssetSearchView from '@/views/static/search-tools/asset-search/AssetSearchView'
import MapSearchView from '@/views/static/search-tools/map-search/MapSearchView'
import PhoneSearchView from '@/views/static/search-tools/phone-search/PhoneSearchView'
import UserDashboardView from '@/views/user-dashboard/UserDashboardView.vue'
import MyInvoicesView from '@/views/my-invoices/MyInvoicesView.vue'
import OperationalNewsView from '@/views/news/operational-news/OperationalNewsView'
import UserAdministrationView from '@/views/user-administration/UserAdministrationView.vue'

import invoices from '@/views/my-invoices/invoices/invoices.vue'
import invoiceSpecifications from '@/views/my-invoices/invoice-specifications/invoice-specifications'
import faultReportingRoutes from '@/apps/fault-reporting/router/routes'
import CompanyAdministrationView from '@/views/company-administration/CompanyAdministrationView.vue'
import companyCreationContainer from '@/views/company-administration/company-creation/company-creation-container.vue'
import companyDetailsContainer from '@/views/company-administration/company-details/company-details-container.vue'
import userDetailsContainer from '@/views/user-administration/user-details/user-details-container.vue'
import userCreationContainer from '@/views/user-administration/user-creation-container/user-creation-container.vue'
import i18n from '@/i18n'
import {
  BREADCRUMBS_QUOTE_ORDER_IFRAMES, BREADCRUMBS_FIBER_NETWORK_CONNECTION, BREADCRUMBS_ADDRESS_REQUEST, BREADCRUMBS_REQUEST_AND_ORDER_EXPRESS_DELIVERY, BREADCRUMBS_BLOCKING_REQUEST, BREADCRUMBS_ORDER_MAPS, BREADCRUMBS_MAP_FILES_SUBSCRIPTION, BREADCRUMBS_DECOMMISSION_REDUCTION_OF_ESTABLISHMENT_PRODUCT, BREADCRUMBS_NODE_INTERNAL
} from '@/constants/breadcrumbs-constants'
import IframeView from '@/views/iframes/IframeView.vue'
import MassUserDeletionView from '@/views/mass-user-deletion/MassUserDeletionView.vue'
import MassUserTransferView from '@/views/mass-user-transfer/MassUserTransferView.vue'
import DynamicFormView from '@/views/dynamic-form/DynamicFormView.vue'

const banner = { heading: i18n.t('header.menu-items.orders') }

export default {
  path: 'dashboard',
  component: {
    template: '<router-view />'
  },
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'dashboard',
      component: LoginLandingView
    },
    {
      path: 'installningar',
      name: 'settings',
      component: UserDashboardView
    },
    {
      path: 'my-reports',
      name: 'my-reports',
      component: MyReportsView
    },
    {
      path: 'my-invoices',
      name: 'my-invoices',
      component: MyInvoicesView,
      redirect: {
        name: 'invoices'
      },
      meta: {
        requiresAuth: true,
        rolesRequired: ['INVOICES']
      },
      children: [
        {
          name: 'invoices',
          path: 'invoices',
          component: invoices
        },
        {
          name: 'invoice-specifications',
          path: 'invoice-specifications',
          meta: { rolesRequired: ['SPECIFICATIONS'] },
          component: invoiceSpecifications
        }
      ]
    },
    {
      path: 'reports-administration',
      name: 'reports-administration',
      meta: { rolesRequired: ['REPORTS'] },
      component: ReportsAdministrationView
    },
    {
      path: 'user-administration',
      meta: { rolesRequired: [
        'ADMINISTRATOR',
        'DELEGATED_ADMINISTRATOR'
      ] },
      component: {
        template: '<router-view />'
      },
      children: [
        {
          path: '',
          name: 'user-administration',
          component: UserAdministrationView
        },
        {
          path: 'user-creation',
          name: 'user-creation',
          component: userCreationContainer
        },
        {
          path: 'user-details',
          name: 'user-details',
          component: userDetailsContainer
        },
        {
          path: 'user-deletion',
          name: 'user-deletion',
          component: MassUserDeletionView
        },
        {
          path: 'user-transfer',
          name: 'user-transfer',
          component: MassUserTransferView
        }
      ]
    },
    {
      path: 'company-administration',
      component: {
        template: '<router-view />'
      },
      meta: { rolesRequired: ['ADMINISTRATOR'] },
      children: [
        {
          path: '',
          name: 'company-administration',
          component: CompanyAdministrationView
        },
        {
          path: 'company-creation',
          name: 'company-creation',
          component: companyCreationContainer
        },
        {
          path: 'company-details',
          name: 'company-details',
          component: companyDetailsContainer
        }
      ]
    },
    {
      path: 'reports-upload',
      name: 'reports-upload',
      component: ReportsUploadView
    },
    {
      path: 'search-tools',
      meta: { rolesRequired: ['SEARCH'] },
      component: {
        template: '<router-view />'
      },
      children: [

        {
          path: 'map-search',
          name: 'map-search',
          component: MapSearchView
        },
        {
          path: 'area-search',
          name: 'area-search',
          component: AreaSearchView
        },
        {
          path: 'connection-number-search',
          name: 'asset-search',
          component: AssetSearchView
        },
        {
          path: 'phone-search',
          name: 'phone-search',
          component: PhoneSearchView
        },
        {
          path: 'address-search',
          name: 'address-search',
          component: AddressSearchView
        }
      ]
    },
    {
      path: 'operational-news',
      name: 'operational-news-view',
      component: OperationalNewsView
    },
    {
      path: 'hantera',
      name: 'iframe-hantera',
      props: {
        breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.MY_ORDERS.SKANOVA,
        banner: { heading: i18n.t('header.menu-items.my-orders') },
        url: process.env.VUE_APP_IFRAME_SKANOVA_PRODUCTS
      },
      component: IframeView
    },
    {
      path: 'hantera-operator',
      name: 'iframe-hantera-change-operator',
      props: {
        breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.MY_ORDERS.SKANOVA,
        banner: { heading: i18n.t('header.menu-items.my-orders') },
        url: process.env.VUE_APP_IFRAME_SKANOVA_PRODUCTS_OPERATORSBYTE
      },
      component: IframeView
    },
    {
      path: 'fraga-bestalla',
      component: {
        template: '<router-view :key="$route.fullPath" />'
      },
      children: [
        {
          path: 'fiber-lagenhet-natanslutning',
          name: 'fiber-network-connection',
          props: {
            breadcrumbItems: BREADCRUMBS_FIBER_NETWORK_CONNECTION,
            banner: {
              heading: i18n.t('product-names.apartment-mains'),
              description: i18n.t('dynamic-forms-descriptions.fiber-network-connection')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'adressforfragan',
          name: 'address-request',
          props: {
            breadcrumbItems: BREADCRUMBS_ADDRESS_REQUEST,
            banner: {
              heading: i18n.t('product-names.address-request'),
              description: i18n.t('dynamic-forms-descriptions.address-request')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'expressforfragan',
          name: 'request-and-order-express-delivery',
          props: {
            breadcrumbItems: BREADCRUMBS_REQUEST_AND_ORDER_EXPRESS_DELIVERY,
            banner: {
              heading: i18n.t('dynamic-forms-titles.request-and-order-express-delivery'),
              description: i18n.t('dynamic-forms-descriptions.request-and-order-express-delivery')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'blockeringsforfragan',
          name: 'blocking-request',
          props: {
            breadcrumbItems: BREADCRUMBS_BLOCKING_REQUEST,
            banner: {
              heading: i18n.t('dynamic-forms-titles.blocking-request'),
              description: i18n.t('dynamic-forms-descriptions.blocking-request')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'kartmaterial',
          name: 'order-maps',
          props: {
            breadcrumbItems: BREADCRUMBS_ORDER_MAPS,
            banner: {
              heading: i18n.t('dynamic-forms-titles.order-maps'),
              description: i18n.t('dynamic-forms-descriptions.order-maps')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'prenumeration-fmo-filer',
          name: 'map-files-subscription',
          props: {
            breadcrumbItems: BREADCRUMBS_MAP_FILES_SUBSCRIPTION,
            banner: {
              heading: i18n.t('dynamic-forms-titles.map-files-subscription'),
              description: i18n.t('dynamic-forms-descriptions.map-files-subscription')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'nedtagning-av-etableringsprodukt',
          name: 'decommission-reduction-of-establishment-product',
          props: {
            breadcrumbItems: BREADCRUMBS_DECOMMISSION_REDUCTION_OF_ESTABLISHMENT_PRODUCT,
            banner: {
              heading: i18n.t('dynamic-forms-titles.decommission-reduction-of-establishment-product')
            }
          },
          component: DynamicFormView
        },
        {
          path: 'Inom-nod',
          name: 'node-internal',
          props: {
            breadcrumbItems: BREADCRUMBS_NODE_INTERNAL,
            banner: {
              heading: i18n.t('dynamic-forms-titles.node-internal'),
              description: i18n.t('dynamic-forms-descriptions.node-internal')
            }
          },
          component: DynamicFormView
        },

        {
          path: 'operatorsbyte',
          name: 'operator-change',
          component: OperatorChangeView
        },
        {
          path: 'telia-wholesale-fiber',
          name: 'iframe-wholesale-fiber',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.WHOLESALE_FIBER,
            banner,
            url: process.env.VUE_APP_IFRAME_WHOLESALE_FIBER
          },
          component: IframeView
        },
        {
          path: 'skanova-fiber-2009',
          name: 'iframe-skanova-fiber-2009',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.SKANOVA_FIBER_2009,
            banner,
            url: process.env.VUE_APP_IFRAME_SKANOVA_FIBER_2009
          },
          component: IframeView
        },
        {
          path: 'skanova-fiber-singel',
          name: 'iframe-skanova-fiber-singel',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.SKANOVA_FIBER_SINGEL,
            banner,
            url: process.env.VUE_APP_IFRAME_SKANOVA_FIBER_SINGEL
          },
          component: IframeView
        },
        {
          path: 'fiber-lagenhet',
          name: 'iframe-fiber-lagenhet',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.FIBER_LAGENHET,
            banner,
            url: process.env.VUE_APP_IFRAME_FIBER_LAGENHET
          },
          component: IframeView
        },
        {
          path: 'fiber-villa',
          name: 'iframe-fiber-villa',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.FIBER_VILLA,
            banner,
            url: process.env.VUE_APP_IFRAME_FIBER_VILLA
          },
          component: IframeView
        },
        {
          path: 'fiber-smaforetag',
          name: 'iframe-fiber-smareforetag',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.FIBER_SMAFORETAG,
            banner,
            url: process.env.VUE_APP_IFRAME_FIBER_SMAFORETAG
          },
          component: IframeView
        },
        {
          path: 'adressanslutning-komplettering',
          name: 'iframe-address-connection',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.FIBER.ADDRESS_CONNECTION,
            banner,
            url: process.env.VUE_APP_IFRAME_ADDRESSANSLUTNING_KOMPLETTERING
          },
          component: IframeView
        },
        {
          path: 'skild-framforing-med-telia-wholesale-fiber',
          name: 'iframe-fiber-performance',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.TJANSTER.FIBER_PERFORMANCE,
            banner,
            url: process.env.VUE_APP_IFRAME_FIBER_PERFORMANCE
          },
          component: IframeView
        },
        {
          path: 'kopparaccess',
          name: 'iframe-kopparaccess',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.KOPPAR.KOPPARACCESS,
            banner,
            url: process.env.VUE_APP_IFRAME_KOPPARACCESS
          },
          component: IframeView
        },
        {
          path: 'koppar-punkt-till-punkt',
          name: 'iframe-koppar-punkt',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.KOPPAR.KOPPAR_PUNKT_TILL_PUNKT,
            banner,
            url: process.env.VUE_APP_IFRAME_KOPPAR_PUNKT_TILL_PUNKT
          },
          component: IframeView
        },
        {
          path: 'ip-stream-enhanced',
          name: 'iframe-ip-stream-enhanced',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.KOPPAR.IP_STREAM_ENHANCED,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_IP_STREAM_ENHANCED
          },
          component: IframeView
        },
        {
          path: 'wholesale-capacity',
          name: 'iframe-capacity-wholesale',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.IP_WDM.CAPACITY_WHOLESALE,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_CAPACITY_WHOLESALE
          },
          component: IframeView
        },
        {
          path: 'ethernet-sweden',
          name: 'iframe-ethernet-sweden',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.IP_WDM.ETHERNET_SWEDEN,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_ETHERNET_SWEDEN
          },
          component: IframeView
        },
        {
          path: 'sdh-sweden',
          name: 'iframe-sdh-sweden',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.IP_WDM.SDH_SWEDEN,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_SDH_SWEDEN
          },
          component: IframeView
        },
        {
          path: 'capacity-basic',
          name: 'iframe-capacity-basic',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.IP_WDM.CAPACITY_BASIC,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_CAPACITY_BASIC
          },
          component: IframeView
        },
        {
          path: '4g-mobile-backhaul',
          name: 'iframe-mobile-backhaul',
          props: {
            breadcrumbItems: BREADCRUMBS_QUOTE_ORDER_IFRAMES.IP_WDM.MOBILE_BACKHAULT,
            banner,
            url: process.env.VUE_APP_IFRAME_ESALES_TOB_4G_MOBILE_BACKHAUL
          },
          component: IframeView
        }
      ]
    },

    ...faultReportingRoutes
  ]
}
