import Cookies from 'js-cookie'
import {
  COOKIE_KEY_ACCESS_TOKEN,
  LOCAL_KEY_ACCESS_TOKEN,
  LOCAL_KEY_EXPIRE_TIME,
  LOCAL_KEY_REFRESH_TOKEN,
  OPTANON_ALERT_BOX_CLOSED,
  OPTANON_CONSENT,
  SESSION_KEY_CODE_VERIFIER,
  SESSION_KEY_RETURN_URI,
  SESSION_KEY_STATE,
  USER_FIELDS
} from '@/common/auth-constants'
import { nanoid } from 'nanoid'
import { addSeconds } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import isAfter from 'date-fns/isAfter'
import getPkce from 'oauth-pkce'
import { getSelectedLocale } from '@/utils/locale.utils'

function removeCookie (cookie) {
  return domain => {
    // path & domain attributes are mandatory for removal to work
    const attributes = {
      domain: domain,
      path: '/'
    }

    Cookies.remove(cookie, attributes)
  }
}

export const removeCookies = () => {
  const cookies = document.cookie.split(';')
  const optanonCookies = [
    OPTANON_ALERT_BOX_CLOSED,
    OPTANON_CONSENT
  ]

  const isOptanonCookie = (cookieName) => optanonCookies.includes(cookieName.trim())

  for (const element of cookies) {
    const cookieName = element.split('=')[0].trim()

    if (isOptanonCookie(cookieName)) {
      continue
    }

    document.cookie = `${cookieName}=; expires=${new Date(0).toUTCString()}; path=/`
  }

  Object
    .keys(Cookies.get())
    .forEach(cookieName => {
      if (isOptanonCookie(cookieName)) {
        return
      }

      const domains = process.env.VUE_APP_COOKIE_DOMAIN.split('|')
      domains.forEach(domain => removeCookie(cookieName, { domain }))
    })
}

export const signOut = () => {
  localStorage.clear()
  sessionStorage.clear()
  removeCookies()
}

export function storeReturnUri () {
  const pathname = window.location.pathname
  const searchParams = window.location.search

  if (pathname.includes(process.env.VUE_APP_LOGIN_PATH) || pathname.includes(
    process.env.VUE_APP_CALLBACK_PATH)) {
    return
  }

  const returnUri = pathname + searchParams
  window.sessionStorage.setItem(SESSION_KEY_RETURN_URI, returnUri)
}

async function buildCodeChallengeAndStoreVerifier () {
  try {
    const { verifier,
      challenge } = await new Promise((resolve, reject) => {
      getPkce(43, (error, result) => error ? reject(error) : resolve(result))
    })

    sessionStorage.setItem(SESSION_KEY_CODE_VERIFIER, verifier)
    return challenge
  } catch (error) {
    return 'FAILED_TO_GENERATE_CHALLENGE'
  }
}

function buildStateAndStore () {
  const state = nanoid()
  sessionStorage.setItem(SESSION_KEY_STATE, state)
  return state
}

function buildAuthenticationUrlParams (codeChallenge, state) {
  const redirectUri = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_CALLBACK_PATH}`
  const responseType = 'code'
  const codeChallengeMethod = 'S256'

  const params = new URLSearchParams()
  params.append('response_type', responseType)
  params.append('code_challenge', codeChallenge)
  params.append('code_challenge_method', codeChallengeMethod)
  params.append('client_id', process.env.VUE_APP_OAUTH_CLIENT_ID)
  params.append('redirect_uri', redirectUri)
  params.append('scope', process.env.VUE_APP_OAUTH_SCOPE)
  params.append('state', state)
  params.append('ui_locales', getSelectedLocale())

  return params
}

function getHost () {
  // TODO temporary beta.teliawholesale.se workaround
  const hostname = window.location.hostname
  const beta = 'beta.teliawholesale.se'
  const prod = 'www.teliawholesale.se'
  const isUseProdIdp = hostname.includes(beta) || hostname.includes(prod)
  const idpEdge = 'idpedge.teliacompany.net'
  const idpEdgePreProd = process.env.VUE_APP_OAUTH_AUTHORITY
  return isUseProdIdp ? idpEdge : idpEdgePreProd
}

function buildAuthenticationUrlWithParams (params) {
  const idpHost = getHost()
  const authorizationUrl = `https://${idpHost}/${process.env.VUE_APP_OAUTH_AUTHORIZATION_ENDPOINT}`

  const url = new URL(authorizationUrl)
  url.search = params.toString()
  return url.toString()
}

export async function buildAuthenticationUrl () {
  const state = buildStateAndStore()
  const codeChallenge = await buildCodeChallengeAndStoreVerifier()
  const params = buildAuthenticationUrlParams(codeChallenge, state)
  return buildAuthenticationUrlWithParams(params)
}

function getRoleLost (roles) {
  return Object.keys(roles).filter(role => roles[role])
}

export async function saveUserDetails (user) {
  const rolesList = JSON.stringify(getRoleLost(user.roles))

  const company = user.company
  const companyName = company ? company.name : 'Unknown'
  const companyCode = company ? company.code : '00'

  localStorage.setItem(USER_FIELDS.FIRST_NAME, user.firstName)
  localStorage.setItem(USER_FIELDS.LAST_NAME, user.lastName)
  localStorage.setItem(USER_FIELDS.COMPANY_NAME, companyName)
  localStorage.setItem(USER_FIELDS.COMPANY_CODE, companyCode)
  localStorage.setItem(USER_FIELDS.USERNAME, user.username)
  localStorage.setItem(USER_FIELDS.ROLES, rolesList)
}

function getStringSize (s) {
  return new Blob([s]).size
}

function buildExpirationTimeInISO (secondsToAdd) {
  const currentTime = new Date()
  const updatedTime = addSeconds(currentTime, secondsToAdd)
  return updatedTime.toISOString()
}

export async function setTokenData ({ access_token,
  refresh_token,
  expires_in }) {
  const isSecure = process.env.NODE_ENV !== 'dev'
  const attributes = {
    secure: isSecure,
    sameSite: 'strict'
  }

  if (getStringSize(access_token) > 4096) {
    throw new Error('Access Token Reached Limit of 4096 bytes!')
  }

  await Cookies.set(COOKIE_KEY_ACCESS_TOKEN, access_token, attributes)
  localStorage.setItem(LOCAL_KEY_REFRESH_TOKEN, refresh_token)
  localStorage.setItem(LOCAL_KEY_EXPIRE_TIME,
    buildExpirationTimeInISO(expires_in))

  if (process.env.NODE_ENV === 'development') {
    // save access_token in local storage for development purposes
    localStorage.setItem(LOCAL_KEY_ACCESS_TOKEN, access_token)
  }
}

export function getReturnUri () {
  const item = sessionStorage.getItem(SESSION_KEY_RETURN_URI)
  if (item === '/') {
    return process.env.VUE_APP_DASHBOARD_PATH
  }
  return item || process.env.VUE_APP_DASHBOARD_PATH
}

function isExpired (isoTimeString) {
  const parsedTime = parseISO(isoTimeString)
  const currentTime = new Date()

  return isAfter(currentTime, parsedTime)
}

export function requiresToRefreshToken () {
  const expirationTime = localStorage.getItem(LOCAL_KEY_EXPIRE_TIME)

  if (!expirationTime) {
    return false
  }

  return isExpired(expirationTime)
}
